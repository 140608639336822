var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-0 pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"header d-flex flex-column justify-center align-center"},[_c('h1',{staticClass:"header__title"},[_vm._v(_vm._s(_vm.$t("pricingPage.title")))]),_c('h3',{staticClass:"header__subtitle"},[_vm._v(_vm._s(_vm.$t("pricingPage.subtitle")))])]),_c('div',{staticClass:"pricing-content"},[_c('div',{staticClass:"pricing-feature-image"}),_c('div',{staticClass:"pricing-feature-gradient"},[_c('div',{staticClass:"d-flex justify-center price-cards"},[_c('PricingCard',{staticClass:"price-card ma-2 ma-sm-5 ma-md-9",attrs:{"title":_vm.$t('pricingPage.bookTypes.hardCover'),"dimensions":_vm.$t('pricingPage.bookTypes.dimensions', {
              width: _vm.bookDetails.hardCover.width,
              height: _vm.bookDetails.hardCover.height,
            }),"price":_vm.bookDetails.hardCover.price,"price-per":_vm.$t('pricingPage.bookTypes.priceText', {
              price: _vm.bookDetails.hardCover.pricePer,
              pageCount: _vm.minPageCount,
            })}}),_c('PricingCard',{staticClass:"price-card ma-2 ma-sm-5 ma-md-9",attrs:{"cardClass":"variant","title":_vm.$t('pricingPage.bookTypes.softCover'),"dimensions":_vm.$t('pricingPage.bookTypes.dimensions', {
              width: _vm.bookDetails.softCover.width,
              height: _vm.bookDetails.softCover.height,
            }),"price":_vm.bookDetails.softCover.price,"price-per":_vm.$t('pricingPage.bookTypes.priceText', {
              price: _vm.bookDetails.softCover.pricePer,
              pageCount: _vm.minPageCount,
            })}})],1),_c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:((entries) => _vm.onIntersect(entries, 'showFeatures')),expression:"(entries) => onIntersect(entries, 'showFeatures')",modifiers:{"once":true}}],staticClass:"features-content"},[_c('v-slide-y-transition',[(_vm.showFeatures)?_c('ul',{staticClass:"features-list"},[_c('li',[_vm._v(_vm._s(_vm.$t("pricingPage.detailText.colors")))]),_c('li',[_vm._v(_vm._s(_vm.$t("pricingPage.detailText.customizable")))]),_c('li',[_vm._v(_vm._s(_vm.$t("pricingPage.detailText.cover")))]),_c('li',[_vm._v(_vm._s(_vm.$t("pricingPage.detailText.pageQuality")))]),_c('li',[_vm._v(" "+_vm._s(_vm.$t("pricingPage.detailText.pageCount", { min: _vm.minPageCount, max: _vm.maxPageCount, }))+" ")])]):_vm._e()]),_c('v-scale-transition',[(_vm.showFeatures)?_c('div',{staticClass:"button-container d-flex justify-center"},[_c('v-btn',{staticClass:"btn mx-10 px-16",attrs:{"color":"text","elevation":"0","to":{ path: '/signup' }}},[_c('span',{staticClass:"btn__text btn__text--white font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("pricingPage.start"))+" ")])]),_c('v-btn',{staticClass:"btn mx-10",staticStyle:{"border-width":"4px"},attrs:{"color":"text","elevation":"0","outlined":"","min-width":"175px"}},[_c('a',{attrs:{"href":"http://help.jambooks.co","target":"_blank"}},[_c('span',{staticClass:"btn__text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("footer.faq"))+" ")])])])],1):_vm._e()])],1)]),_c('img',{staticClass:"book-stack",attrs:{"src":require("@/assets/stack_of_books.png"),"width":"100%"}}),_c('Inspiration')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }